import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { privacy as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';

import {
    Hero,
    SEO,
} from '@brandedholdings/react-components';

import PageWrapper from '../components/DefaultPageWrapper';

import "../stylesheets/global.scss";



class PrivacyPolicy extends React.Component {
    getChildContext() {
        return { siteMeta };
    }
    
    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper>
                <SEO post={ post } />
                <Hero
                    heading="Privacy Policy"
                    subheading={null}
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--empty"
                    actionModifiers={null}
                />
                <div className="hero__accent icon icon--arrow-dotted"></div>


                <div className="layout">
                    <div className="layout-content">
                        <div className="layout">
                            <div className="layout-content">
                                

                        <p>
Your privacy is important to us. This Privacy Policy is designed to assist you in making
                            informed decisions when using our website, understanding how we collect and use your
                            personal information and how our practices concerning privacy pertain to you as a user of
                            our system. If you have questions or complaints regarding our privacy policy or practices,
                            please contact us at
                            {' '}
                            <a href={`mailto:${  siteMeta.email}`}>{ siteMeta.email }</a>
. We may
                            change this policy from time to time by updating this page.

                        </p>

                        <h4>The Information We Collect</h4>
                        <p>
This notice applies to all information collected or submitted on the
                            {' '}
                            { siteMeta.name }
                            {' '}
                            website as well as the mobile version(s) of our site. We may collect all information you
                            provide, whether through our websites or other channels, such as your name, street address,
                            e-mail address, telephone number (home, work and mobile), date of birth, Social Security
                            Number, financial account information, income information, employment information and other
                            personal, financial, or demographic information.

                        </p>

                        <p>
Our website includes Social Media Features, such as the Facebook Like button and Widgets,
                            such as the Share this button or interactive mini-programs that run on our site. These
                            features may collect your IP address, which page you are visiting on our site, and may set a
                            cookie to enable the feature to function properly. Social Media Features and Widgets are
                            either hosted by a third-party or hosted directly on our website. Your interactions with
                            these features are governed by the privacy policy of the company providing it.

                        </p>

                        <p>
Our website offers publicly accessible blogs. You should be aware that any information you
                            provide in these areas may be read, collected, and used by others who access them. To
                            request removal of your personal information from our blog or community forum, contact us at
                            {' '}
                            <a href={`mailto:${ siteMeta.email}`}>{ siteMeta.email }</a>
. In some cases, we may not be
                            able to remove your personal information, in which case we will let you know if we are
                            unable to do so and why.

                        </p>

                        <p>
We do not ask you for, access or track any location-based information from your mobile device
                            at any time while downloading or using our Mobile Site or services.

                        </p>

                        <h4>Our Commitment to Privacy</h4>
                        <p>
Your privacy is important to us. To better protect your privacy we provide this notice
                            explaining our online information practices and the choices you can make about the way your
                            information is collected and used. To make this notice easy to find, we make it available on
                            our homepage and at every point where personally identifiable information may be
                            requested.

                        </p>

                        <h4>Our Commitment to Data Security</h4>
                        <p>
To prevent unauthorized access, maintain data accuracy, and ensure the correct use of
                            information, we have put in place appropriate physical, electronic, and managerial
                            procedures to safeguard and secure the information we collect online.

                        </p>

                        <p>
The security of your personal information is important to us. We follow generally accepted
                            industry standards to protect the personal information submitted to us, both during
                            transmission and once we receive it. No method of transmission over the Internet, or method
                            of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute
                            security. When you enter sensitive information (such as a credit card number) on our order
                            forms, we encrypt the transmission of that information using secure socket layer technology
                            (SSL).

                        </p>
                        <span id="information-use" className="anchor" />
                        <h4>The Way We Use Information</h4>
                        <p>
By agreeing to this Privacy Policy or by using this website, you authorize us and third
                            parties to whom you submit your information, to use the information you submit to obtain
                            other information about you from third-parties, such as your Social Security Number, where
                            such information was incompletely or improperly filled out on our registration form. Such
                            third-parties include, but are not limited to, consumer reporting agencies, consumer credit
                            bureaus and other outside parties as permitted or required by applicable laws. Any and all
                            information that you submit and that is collected from such third-parties may be used to
                            determine your eligibility for any product, service or offer. If you choose to provide
                            information, such as your Social Security Number, that information may be used to determine
                            your financial status. If you do not provide such information, other information that you
                            provide may be used for the purpose of verifying your eligibility for a financial service
                            provider or for any other programs, offer or services. Upon qualification, your specific
                            financial information will be stored and utilized by your applicable financial service
                            provider pursuant to the terms of that financial service provider’s privacy policy.

                        </p>

                        <p>
If you choose to provide personal information, it will be used for and/or shared with trusted
                            third parties such financial service providers, credit bureaus, and for marketing products
                            and services that you might find of interest. We reserve the right to share, rent, sell or
                            otherwise disclose your information with/to third parties in accordance with applicable laws
                            and as described herein. These third-party businesses may include, but are not limited to:
                            providers of direct marketing services and applications, including lookup and reference,
                            data enhancement, suppression and validation; e-mail marketers; wireless services providers
                            and telemarketers. Information collected by us may be added to our databases and used, in
                            accordance with consents provided by you, for future instant messaging, telemarketing, SMS
                            text-messaging, e-mails or postal mailings regarding site updates, new products and
                            services, upcoming events, and/or status of orders and/or financial services sought online.
                            By using this website, you agree that you may be contacted in any manner contemplated in
                            this section even if your number is found on a do-not-call registry or similar registry. We
                            may also employ other companies and individuals to perform certain functions on our behalf.
                            Examples include sending direct and electronic mail, removing duplicate information from
                            customer lists, analyzing data, and providing marketing analysis. These agents have access
                            to our users’ personal information as needed to perform their functions for our sites but
                            may not be used for their own marketing purposes. In addition, we may also disclose your
                            personal information as required by law, such as to comply with a subpoena, or similar legal
                            process.

                        </p>

                        <p>
You agree that by submitting your information at the website, and in accordance with the
                            prior express written consent you provided, you agree to receive mobile marketing including,
                            but not limited to, text-message based marketing (“SMS Messages”), from us and our
                            third-party advertisers and marketers about our products and services or other financial
                            products and services we believe may be of interest to you. You further agree that based on
                            your individual carrier and plan, you may incur charges associated with such SMS Messages
                            and that you agree to be solely responsible for such charges. You represent that you are at
                            least 18 years of age and the owner or authorized user of the wireless device on which
                            messages will be received, and that you are authorized to approve the applicable charges.
                            Should you hereafter choose to opt-out of receiving SMS Messages from the individual sender,
                            you may opt-out by texting “STOP” or “OPT-OUT” as specifically set forth in the
                            communication. Notwithstanding that your mobile telephone number may be listed on state
                            and/or federal do-not-call registries, we retain the right to contact you via SMS Messages.
                            In addition, by registering and/or using the website, you agree that such act constitutes an
                            inquiry and/or application for purposes of the Amended Telemarketing Sales Rule (16 CFR 310
                            et seq.), as amended from time to time (the “Rule”). Notwithstanding that your telephone
                            number may be listed at the Federal Trade Commission’s Do-Not-Call List, we retain the right
                            to contact you via telemarketing in accordance with the Rule.

                        </p>

                        <p>
We may also use your personal, demographic and profile data to improve our website, for
                            statistical analysis, for marketing and promotional purposes, and for editorial or feedback
                            purposes. Information collected by us may be added to our databases and used for future
                            e-mails, telemarketing or postal mailings regarding site updates, new products and services,
                            upcoming events, and/or status of orders placed online. See “Your Opt-Out Rights” below.

                        </p>

                        <h4>Surveys or Contests</h4>
                        <p>
From time-to-time, we may provide you the opportunity to participate in surveys on our site.
                            If you participate, we will request certain personally identifiable information from you.
                            Participation in these surveys or contests is completely voluntary and you therefore have a
                            choice whether or not to disclose this information. The requested information typically
                            includes contact information (such as name, telephone number, email and shipping address),
                            and demographic information (such as zip code). We use this information to improve our
                            sites, monitor site traffic or personalize the sites.

                        </p>

                        <h4>Electronic Disclosure</h4>
                        <p>
By submitting your information via the Internet, you agree to receive certain notifications,
                            disclosures and/or documents electronically. This Consent to Electronic Disclosures applies
                            to the information you are providing on this Site and any other communications we or any
                            third-party financial service provider may provide to you. By submitting your information,
                            you agree to receive all such notifications, disclosures and/or documents from us or any
                            third-party financial service provider in electronic form or online in accordance with this
                            document. You may withdraw your consent prior to submitting your information by exiting the
                            website or by closing your browser. However, you will not be able to submit your information
                            if you withdraw your consent by exiting the website or closing your browser. Following the
                            submission of your information, you may be able to withdraw your consent to future
                            electronic disclosures from a third-party financial service provider by contacting the
                            financial service provider directly, but due to rules regarding the timing of delivery of
                            disclosures that need to be made to you, you acknowledge that an opt-out notice may not be
                            received and acted upon by such third-party financial service provider prior to such
                            disclosures being delivered to you electronically. We do not process opt-out communications
                            for third-party financial service providers, and each third-party financial service provider
                            establishes its own rules regarding opting out of future electronic communications and
                            disclosures.

                        </p>

                        <p>
All notifications, disclosures and/or documents that we or any third-party financial service
                            provider provide to you in electronic format will be provided either (i) via e-mail; (ii) by
                            access to a secure customer service website which will be provided to you in an e-mail
                            notice sent to you when the documents are available; (iii) by posting on this Site or the
                            financial service provider’s site; (iv) by SMS text messaging to your mobile device; or (v)
                            by posting on a website designated for that purpose. All notifications, disclosures and/or
                            documents provided in electronic or paper format from us or any third-party financial
                            service provider to you will be considered “in writing,” and you should print a copy for
                            your records. You may obtain any notifications, disclosures and/or documents in paper form
                            without charge from us by printing them yourself from our website or the appropriate
                            third-party financial service provider’s website when available. To access, view and retain
                            the notifications, disclosures, and/or documents available to you in electronic form, you
                            must have Internet access, sufficient electronic storage capacity, an e-mail account with an
                            Internet service provider e-mail software.

                        </p>

                        <p>
You agree and intend the federal Electronic Signatures in Global and National Commerce Act to
                            apply to (i) your consent to receive notifications, disclosures and/or documents in
                            electronic form; (ii) the information you provide, and (iii) our and/or any third-party
                            financial service providers’ ability to conduct business with you by electronic means.

                        </p>

                        <p>
By completing and submitting your information, you (i) agree to provide us with an accurate
                            and complete e-mail address and other required information requested on this Site; (ii)
                            confirm your consent to receive notifications, disclosures and/or documents from us and any
                            third-party financial service provider in electronic format; (iii) affirmatively demonstrate
                            your ability to access the notifications, disclosures and/or documents in electronic form;
                            (iv) confirm that you have provided a current e-mail address at which electronic
                            notifications, documents and/or disclosures can be sent to you; (v) acknowledge that you
                            have read and reviewed the terms contained in this Electronic Disclosure section; and (vi)
                            agree to the terms contained in this Electronic Disclosure section.

                        </p>

                        <p>
We and any third-party financial service providers, reserve the right, in our sole
                            discretion, to discontinue electronic provision of notifications and/or documents. You will
                            be provided with notice of any such termination or change as required by law.

                        </p>

                        <p>
To facilitate electronic commerce, to reduce the expense of records storage, and to obtain
                            the benefits of faster access to records, you acknowledge and agree that all records may be
                            stored electronically; and that neither we nor any third-party financial service provider
                            will retain and have no obligation to retain any original or electronic documents for any
                            period of time beyond the regulatory requirements. This applies to all notifications,
                            disclosures and/or documentation. You further acknowledge and understand that all original
                            and electronic notification, disclosures and/or documentation will be routinely destroyed,
                            but not before the period of time designated by regulatory requirements. Records may be
                            stored electronically via imaging, scanning, filming or other technology used for the
                            storage of documentation via internal processes or third-party processors that we or any
                            third-party financial service provider approve for these services. You agree that such
                            storage shall be secure, and further agree that such records shall for all purposes be
                            recognized and admissible in evidence or otherwise to prove the agreements, rights and
                            obligations of the parties pursuant to any such records. We will retain your information for
                            as long as needed to provide you services. If you wish to request that we no longer use your
                            information to provide you services please contact us at
                            {' '}
                            <a
                                href={`mailto:${  siteMeta.email}`}
                                                        >
                                                                                            { siteMeta.email }

                                                                                                                                            </a>
. We will retain and use your
                            information as necessary to comply with our legal obligations, resolve disputes, and enforce
                            our agreements.

                        </p>

                        <h4>Third-Party Sites</h4>
                        <p>
Please be advised that in certain circumstances you will be redirected to the applicable
                            third-party financial service provider’s or seller’s website after you have submitted your
                            information. By submitting your personal information to the respective third-party financial
                            service provider or seller in connection with providing your information for purchase from
                            the third-party financial service providers or seller, such submission will subject you to
                            the third-party financial service provider’s or seller’s privacy policy and terms and
                            conditions, as applicable. We do not endorse, nor are we responsible for the accuracy of the
                            privacy policies and/or terms and conditions of each of the third-party financial service
                            providers or sellers that may advertise at the website. The entities that advertise and/or
                            place banner ads at the website, including but not limited to these third-party financial
                            service providers and sellers, are independent third parties and are not affiliated with us.
                            In addition, our website contains links to other third-party websites and note that their
                            privacy policy will govern the collection and use practices while on their website.

                        </p>

                        <h4>Display Advertising</h4>
                        <p>
Our site has implemented Google Analytics features based on Display Advertising such as
                            Remarketing and Google Display Network Impression Reporting. You can opt-out of Google
                            Analytics for Display Advertising and customize Google Display Network ads using the Ads
                            Settings at www.google.com. We use Remarketing with Google Analytics to advertise online.
                            Third-party vendors, including Google, show our ads on sites across the Internet. We and our
                            third-party vendors, including Google, use first-party cookies (such as the Google Analytics
                            cookie) and third-party cookies (such as the DoubleClick cookie) together to inform,
                            optimize, and serve ads based on someone’s past visits to our website and to report how our
                            ad impressions, other uses of ad services, and interactions with these ad impressions and ad
                            services are related to visits to our site.

                        </p>

                        <span id="opt-out-rights" />
                        <h4>Your Opt-Out Rights</h4>
                        <p>You may opt-out of receiving communications from us and or our third-party associates by not submitting your information. During registration and/or when you submit personally identifiable information to us at the website, you have opted-in to request that we share your personal information with third parties to receive marketing communications for the specific purposes of receiving offers that you have applied for on our websites. When contacted by any of these companies or third parties, you should notify them directly of your choices regarding their use and sharing of your information and to opt-out of receiving additional offers from them or any other third party with whom we have shared your information (see &ldquo;<a href="#information-use">The Way We Use Information</a>&rdquo; above). As noted, we use personal information to provide promotional offers through direct marketing, including without limitation e-mail, telephone, or SMS Messages Service to our website users. We may maintain separate e-mail lists for different purposes. If e-mail recipients wish to end their e-mail subscription from a particular list, they need to follow the instructions at the end of each e-mail message to unsubscribe from the particular list. To opt-out from receiving any additional email communications regarding the website, please send your request in writing via email to <a href={'mailto:' + siteMeta.email}>{siteMeta.email}</a>.</p>
                        
                        <span id="do-not-sell-personal-info" className="anchor" />
                        <div className="highlight-content">
                            <p><strong>Do not sell my personal information</strong><br />
                            You may also opt-out of having your personally identifiable information sold to third-parties. During registration and/or when you submit personally identifiable information to us at the website, you have opted-in to request that we share your personal information with third parties, including Loan Providers and/or other financial service providers for the specific purposes of receiving offers that you have applied for on our websites. When contacted by any of these companies or third parties, you should notify them directly of your choices regarding their use and sharing of your information. <strong><em>Please note that if you opt-out of having your personally identifiable information sold to third-parties, including Loan Providers and/or other financial service providers, you will not be able to receive the services sought for which you originally visited this website.</em></strong></p>
                            <p>To opt-out from having your personally identifiable information sold to third-parties, including Loan Providers and/or other financial service providers, please send your request in writing via email to <a href={'mailto:' + siteMeta.email}>{siteMeta.email}</a> or via postal mail to {siteMeta.address.street}, {siteMeta.address.street2}, {siteMeta.address.city}, {siteMeta.address.state} {siteMeta.address.zip}.</p>
                            <p>In addition, please note that if you subsequently sign up at one of our other websites for other product or service offerings, you will need to again address any information sharing preferences that you had previously established through this website.</p>
                        </div>
                        <br />
                        <h4>Text Message Policy</h4>
                            
                            <p>By providing your cell phone number, you have provided Finance-Matrix.com with consent to send you recurring text messages in conjunction with the services you have requested.
                            <ul className="list">
                                <li>You may opt-out at any time by texting the word STOP. An opt-out confirmation message will be sent back to you.</li>
                                <li>For help, text the word HELP.</li>
                                <li>Message frequency varies. Message and data rates may apply.</li>
                                <li>To request support, Text HELP.</li>
                            </ul>
                            </p>
                            
                            <p>MMS - If your phone doesn’t support MMS, you’ll receive SMS instead.</p>
                            <p>This program is supported by all U.S. carriers. Finance-Matrix.com, carriers (including but not limited to T-Mobile) and any service provider used by Finance-Matrix.com to send messages are not liable for delayed or undeliverable messages.</p>

                        <span id="personal-identifiable-info" className="anchor" />
                        <h4>Access to Personally Identifiable Information</h4>
                        <p>We will retain your information for as long as needed to provide you services. If you would like to review, update or remove your personally identifiable information, you may submit your request by contacting us at <a href={'mailto:' + siteMeta.email}>{siteMeta.email}</a>, sending your request via postal mail to {siteMeta.address.street}, {siteMeta.address.street2}, {siteMeta.address.city}, {siteMeta.address.state} {siteMeta.address.zip}. In order for us to process your request, we will need to be able to verify your identity to the extent necessary to ensure that you are the person you represent yourself to be. As appropriate under the circumstances of the request, you may be required to comply with one of the following identification verification methods: </p>

                        <ol className="list">
                            <li>Certification of Identity: Provision of a completed Certification of Identity. A template certification of identity is available <a href="/assets/pdf/consumer-intitiated-data-inquiry.pdf">here</a>.</li>
                            <li>Official Photo Identification: Provision of an official photo identification, which includes, but is not limited to, the following: 
                                <ul className="list">
                                    <li>A valid driver&rsquo;s license.</li>
                                    <li>An unexpired passport.</li>
                                    <li>An unexpired federal government-issued employee identification card.</li>
                                </ul>
                            </li>
                        </ol>

                        <p>You will be provided a reasonable amount of time to provide sufficient identity verification. Any request may be denied for lack of sufficient identity verification.</p>

                        <h4>Our Commitment to Children’s Privacy</h4>
                        <p>
Protecting the privacy of children is especially important. For this reason, we never collect
                            or maintain personal information at our website from those whom we know are under the age of
                            18 years old. In the event that information is inadvertently collected from children, it
                            will not knowingly be used for any marketing or promotional purposes whatsoever, either
                            inside or outside of our website. If we obtain actual knowledge that such information has
                            been collected, that information will be immediately deleted from our database. The
                            information and services provided by us or our affiliates, sponsors, and advertisers are not
                            intended to be viewed by persons under 18 years old.

                        </p>

                        <h4>Use of Tracking Technologies</h4>
                        <p>
Our websites and mobile sites use cookies to enhance and personalize your online experience
                            and to enable our websites or mobile sites to recognize you as a previous visitor. A cookie
                            is a small amount of data, which often includes an anonymous unique identifier that is sent
                            to your browser from a website’s computer and stored on your computer’s hard drive. You can
                            adjust the settings on your browser to erase or block such technologies from your computer
                            if you want to, but if you do so, you will not be able to use or access
                            the
                            {' '}
                            { siteMeta.name }
                            {' '}
Web Services.

                        </p>

                        <p>
Each website can send its own cookie to your browser if your browser’s preferences allow it,
                            but (to protect your privacy) your browser only permits a website to access the cookies it
                            has already sent to you, not the cookies sent to you by other sites. Many sites do this
                            whenever a user visits their website in order to track online traffic flows. Cookies record
                            information about your online preferences and may be tied to your personally identifiable
                            information such as your email address for these purposes. Users have the opportunity to set
                            their computers to accept all cookies, to notify them when a cookie is issued, or not to
                            receive cookies at any time. Each browser is different, so check the “Help” menu of your
                            browser to learn how to change your cookie preferences.

                        </p>

                        <p>
As is true of most websites, we gather certain information automatically and store it in log
                            files. This information includes internet protocol (IP) addresses, browser type, internet
                            service provider (ISP), referring/exit pages, operating system, date/time stamp, and
                            clickstream data.

                        </p>

                        <p>
We use this information, which does not identify individual users, to analyze trends, to
                            administer the site, to track users’ movements around the site and to gather demographic
                            information about our user base as a whole. We do not link this automatically-collected data
                            to personally identifiable information.

                        </p>

                        <p>
Our websites and mobile sites also allow its partners and advertisers that are presenting
                            advertisements on the
                            {' '}
                            { siteMeta.name }
                            {' '}
Web Services to set their cookies, or apply web
                            beacons or similar technologies, to your computer. We have no access or control of these
                            third-party tracking technologies. This information may be used to:

                        </p>

                        <ol className="list">
                            <li>provide security within shopping baskets or transactions;</li>
                            <li>
authenticate your Screen Name before you can access your Personal Information or Web
                                Services Account for added security;
                            </li>
                            <li>
personalize features of the
                                {' '}
                                { siteMeta.name }
                                {' '}
Web Services;
                            </li>
                            <li>
enable you to participate in certain online activities, such as making sure that each
                                person only votes in an online poll once;
                            </li>
                            <li>
help measure and research the effectiveness of the
                                {' '}
                                { siteMeta.name }
                                {' '}
Web Services,
                                including collecting statistics on your response to advertisements, e-mail and other
                                communications you receive;
                            </li>
                            <li>remember the preferences and registration data that you give us.</li>
                        </ol>

                        <p>
Our websites, mobile sites, and our associated third parties may also use ad network
                            providers to help present advertisements on
                            {' '}
                            { siteMeta.name }
                            {' '}
Web Services and other
                            websites. These ad network providers may use cookies, or web beacons or similar technologies
                            on your computer to tailor content, advertisements to your preferences, help target, measure
                            and track your visits and the effectiveness of advertisements and promotions, using data
                            gathered over time and across their networks of Web pages to determine or predict the
                            characteristics and preferences of their audience and to provide security within shopping
                            baskets or transactions. The use of cookies, web beacons, or similar technologies is subject
                            to their own privacy policies, not this one. 
                        </p>

                        <h4>Changes in this Privacy Statement</h4>
                        <p>
We may update this privacy statement to reflect changes to our information practices. If we
                            make any material changes we will notify you by email, SMS Messages or by means of a notice
                            on our websites. We encourage you to periodically
                            review this page for the latest information on our privacy practices.

                        </p>

                        <h4>How to Contact Us</h4>
                        <p>
Should you have other questions or concerns about these privacy policies, please send us an
                            email at
                            {' '}
                            <a href={`mailto:${  siteMeta.email}`}>{ siteMeta.email }</a>
.

                        </p>
                        <p>
                            <address>
                                <div>{ siteMeta.address.name }</div>
                                <div>{ siteMeta.address.street }</div>
                                { siteMeta.address.street2 ? (
                                    <div>{ siteMeta.address.street2 }</div>
                                ) : '' }
                                <div>
                                    { siteMeta.address.city }
,
                                    {' '}
                                    { siteMeta.address.state }
                                    {' '}
                                    { siteMeta.address.zip }
                                </div>
                            </address>
                        </p>
                        <p>Last Updated: April 27, 2022</p>





















                            </div>
                        </div>
                    </div>
                </div>




            </PageWrapper>
        );
    }
};

PrivacyPolicy.childContextTypes = {
    siteMeta: PropTypes.object
};

export default PrivacyPolicy;
